import React from 'react';
import cls from 'classnames';
import style from './character.module.scss';

export interface Props {
  name: string;
  story: React.ReactNode | string;
  image: React.ReactNode;
  color:
    | 'black'
    | 'red'
    | 'purple'
    | 'blue'
    | 'green'
    | 'orange'
    | 'white'
    | 'lightblack'
    | 'yellow';
  variant: 'left' | 'right';
}

const Character: React.FC<Props> = (props) => {
  return (
    <div className={cls(style.CharacterContainer, props.color)}>
      <div className={cls(style.inner, style[props.variant])}>
        <div className={style.story}>
          <h2>{props.name}</h2>
          {props.story}
        </div>

        <div className={style.image}>{props.image}</div>
      </div>
    </div>
  );
};

export default Character;
