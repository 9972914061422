import React from 'react';
import FluidImage from '../../common/FluidImage/FluidImage';
import Character from './character';

interface Props {
  variant: 'left' | 'right';
}

const Lance = ({ variant }: Props) => (
  <Character
    name="Lance"
    story={
      <p>
        "When He broke the third seal, I heard the third living creature saying,
        “Come.” I looked, and behold, a black horse; and he who sat on it had a
        pair of scales in his hand. And I heard something like a voice in the
        center of the four living creatures saying, “A quart of wheat for a
        denarius, and three quarts of barley for a denarius; but do not damage
        the oil and the wine.” Revelation 6:5-6
      </p>
    }
    image={<FluidImage imageName="character-Lance.png" />}
    variant={variant}
    color="green"
  />
);

export default Lance;
