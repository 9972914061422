import React from 'react';
import FluidImage from '../../common/FluidImage/FluidImage';
import Character from './character';

interface Props {
  variant: 'left' | 'right';
}

const Kaine = ({ variant }: Props) => (
  <Character
    name="Kaine"
    story={
      <p>
        When He broke the second seal, I heard the second living creature
        saying, “Come.” And another, a red horse, went out; and to him who sat
        on it, it was granted to take peace from the earth, and that men would
        slay one another; and a great sword was given to HIM. Revelation 6:3-4
      </p>
    }
    image={<FluidImage imageName="character-Kaine.png" />}
    variant={variant}
    color="red"
  />
);

export default Kaine;
