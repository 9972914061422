import React, { useEffect } from 'react';
import FluidImage from '~/components/common/FluidImage/FluidImage';
import SEO from '~/components/common/SEO/SEO';
import Layout from '~/components/layout/layout/layout';
import HeroContainer from '~/components/layout/hero-container/hero-container';
import {
  Adamu,
  Annora,
  Galen,
  Kaine,
  Lance,
  Wahid,
} from '~/components/pages/characters';

let leftOrRight = 0;
const getLeftOrRight = (): 'right' | 'left' =>
  leftOrRight++ % 2 ? 'right' : 'left';

const ChararctersPage = () => {
  useEffect(() => {
    leftOrRight = 0;
  }, []);

  return (
    <Layout>
      <SEO title="Characters" />
      <HeroContainer title="Characters">
        <FluidImage imageName="synopsis.jpeg" />
      </HeroContainer>
      <Adamu variant={getLeftOrRight()} />
      <Wahid variant={getLeftOrRight()} />
      <Kaine variant={getLeftOrRight()} />
      <Lance variant={getLeftOrRight()} />
      <Galen variant={getLeftOrRight()} />
      <Annora variant={getLeftOrRight()} />
    </Layout>
  );
};

export default ChararctersPage;
