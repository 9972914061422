import React from 'react';
import FluidImage from '../../common/FluidImage/FluidImage';
import Character from './character';

interface Props {
  variant: 'left' | 'right';
}

const Galen = ({ variant }: Props) => (
  <Character
    name="Galen"
    story={
      <p>
        Then I saw when the Lamb broke one of the seven seals, and I heard one
        of the four living creatures saying as with a voice of thunder, “Come.”
        I looked, and behold, a white horse, and he who sat on it had a bow; and
        a crown was given to him, and he went out conquering and to conquer.
        Revelation 6:1
      </p>
    }
    image={<FluidImage imageName="character-Galen.png" />}
    variant={variant}
    color="orange"
  />
);

export default Galen;
