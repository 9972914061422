import React from 'react';
import FluidImage from '../../common/FluidImage/FluidImage';
import Character from './character';

interface Props {
  variant: 'left' | 'right';
}

const Adamu = ({ variant }: Props) => (
  <Character
    name="Adamu"
    story={
      <>
        <p>In the beginning God created the heaven and the earth.</p>
        <p>GENESIS 1:1 KJV</p>
      </>
    }
    image={<FluidImage imageName="character-Adamu.png" />}
    variant={variant}
    color="blue"
  />
);

export default Adamu;
