import React from 'react';
import FluidImage from '../../common/FluidImage/FluidImage';
import Character from './character';

interface Props {
  variant: 'left' | 'right';
}

const Annora = ({ variant }: Props) => (
  <Character
    name="Annora"
    story={
      <p>
        And when he had opened the fourth seal, I heard the voice of the fourth
        beast say, Come and see. And I looked, and behold a pale horse: and his
        name that sat on him was Death, and Hell followed with him. And power
        was given unto them over the fourth part of the earth, to kill with
        sword, and with hunger, and with death, and with the beasts of the
        earth. Revelation 6:7
      </p>
    }
    image={<FluidImage imageName="character-Annora.png" />}
    variant={variant}
    color="purple"
  />
);

export default Annora;
