import React from 'react';
import FluidImage from '../../common/FluidImage/FluidImage';
import Character from './character';

interface Props {
  variant: 'left' | 'right';
}

const Wahid = ({ variant }: Props) => (
  <Character
    name="Wahid"
    story={
      <p>
        Wahid is a genetically engineered super-soldier, programmed to be a
        weapon of mass destruction. Created by the secret order of Saddam
        Hussein. Master of various fighting skills and weapon arts. Superhuman
        strength, speed, hardened skin, unlimited agility and peak human
        regenerative ability.
      </p>
    }
    image={<FluidImage imageName="character-Wahid.png" />}
    variant={variant}
    color="black"
  />
);

export default Wahid;
